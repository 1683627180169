.App {
  text-align: center;
  font-family: "Hiragino Kaku Gothic ProN", sans-serif;
}

.margin-item {
  height: 50px;
}
.margin-item-min {
  height: 5px;
}

.stelse {
  display: none;
}

.button-logout {
  display: flex;
  justify-content: right;
}

.li-style-none {
  list-style: none;
  line-height: 30px;
  text-align: left;
}

.size {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.size::before{
  content: "";
  display: block;
  padding-top: 100%;
}

.size > img {
  position: absolute;
  object-fit: cover;
  object-position: center;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.c-section-wrapin {
  margin: 0 auto;
  max-width: 575px;
  position: relative;
  padding: 0 1rem;
  text-align: center;
  width: 100%;
}

.p-grid-row {
  display: flex;
  flex-flow: row wrap;
}

.search {
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 400px;
  z-index: 10;
  text-align: left;
}

.search input {
  padding: 0.5rem;
  font-size: 1.5rem;
  width: 100%;
}

h1{
  text-align: left;
}

.c-section__loading {
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-flow: column;
  justify-content: center;
  opacity: 0.9;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 9999;
}

.c-section__loading > p {
  color: #FDC04F;
  font-weight: 600;
  margin-top: 1rem;
  text-align: center;
}